<template>
  <div class="c-playlists-box" :class="{ 'c-playlists-box--white': color === 'white' }" @click="toPlaylistsInfo">
    <div class="playlists-box item-hover--bottom">
      <img class="playlists-box__img" src="@/assets/explore/playlists_img.jpg" />
      <div class="playlists-box__overlay"></div>
      <p class="playlists-box__learn-more">了解更多</p>
    </div>
    <p class="playlists-title">{{ data.title }}</p>
    <p class="playlists-desc" :class="{ 'playlits-desc--hide-desc-below-portrait': hideDescBelowPortrait }">
      {{ data.desc }}
    </p>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'PlaylistsBoxPopular',
  props: {
    data: {
      type: Object,
      default: () => ({
        id: '432',
        img: '',
        title: 'International Women’s Day 2020',
        desc: 'Here is the Playlist for all the women out there to celebrate the international women’s day 2020!',
      }),
    },
    // 字的顏色
    color: {
      type: String,
      default: 'black',
    },
    hideDescBelowPortrait: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();

    const toPlaylistsInfo = () => {
      router.push({
        name: 'explore-playlists-info',
        query: {
          id: props.data.id,
          title: props.data.title,
        },
      });
    };

    return {
      toPlaylistsInfo,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-playlists-box {
  // @include padding(0px 0px 20px);
  white-space: normal;

  &--white {
    .playlists-title {
      color: $c-white;
    }

    .playlists-desc {
      color: $c-white;
    }
  }
}
.playlists-box {
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;

  &:hover {
    .playlists-box__overlay {
      display: inline-block;
      background-color: rgba($c-black, 0.5);
      opacity: 1;
    }

    .playlists-box__learn-more {
      opacity: 1;
    }
  }

  &__img {
    width: 100%;
    vertical-align: bottom;
  }

  &__overlay {
    @include position(tl, 0, 0);
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.3s;
  }

  &__learn-more {
    @include position(center);
    @include font-style($c-white, 12, 400, 0.8px);
    @include padding(0 0 0 15px);
    opacity: 0;
    transition: 0.3s;

    &::before {
      content: '';
      @include circle(6px);
      @include position(tl, 60%, 0);
      transform: translateY(-50%);
      display: inline-block;
      background-color: $c-white;
    }
  }
}

.playlists-title {
  margin-top: 10px;
  @include font-style($c-black, 16, 800, 1px);
}

.playlists-desc {
  margin-top: 10px;
  @include font-style($c-assist4, 14, 400, 1px);

  // themeInfo 使用
  &--hide-desc-below-portrait {
    display: none;
  }
}

@media screen and (min-width: $portrait) {
  .playlists-desc {
    &--hide-desc-below-portrait {
      display: block;
    }
  }
}

@media screen and (min-width: $tablet) {
  .c-playlists-box {
    // padding-bottom: 20px;
  }
  .playlists-title {
    margin-top: 20px;
    @include font-size(20);
  }
}
</style>
