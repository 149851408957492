<template>
  <div class="explore-icon explore-icon--playlists">
    <svg
      id="Group_2671"
      data-name="Group 2671"
      xmlns="http://www.w3.org/2000/svg"
      width="24.377"
      height="24.377"
      viewBox="0 0 24.377 24.377"
    >
      <path
        id="路径_971"
        data-name="路径 971"
        d="M-441.477-296.919a.371.371,0,0,1-.481-.21,5.965,5.965,0,0,1,3.368-7.724.37.37,0,0,1,.481.21.371.371,0,0,1-.21.481,5.223,5.223,0,0,0-2.948,6.762A.371.371,0,0,1-441.477-296.919Z"
        transform="translate(448.601 311.495)"
        :fill="color.sub"
      />
      <path
        id="路径_972"
        data-name="路径 972"
        d="M-434.156-293.727a.372.372,0,0,1-.481-.21.372.372,0,0,1,.21-.481,5.223,5.223,0,0,0,2.95-6.762.37.37,0,0,1,.21-.481.371.371,0,0,1,.481.21A5.966,5.966,0,0,1-434.156-293.727Z"
        transform="translate(448.522 311.463)"
        :fill="color.sub"
      />
      <path
        id="路径_970"
        data-name="路径 970"
        d="M-436.477-287.187a12.2,12.2,0,0,1-12.188-12.188,12.2,12.2,0,0,1,12.188-12.189,12.2,12.2,0,0,1,12.189,12.189A12.2,12.2,0,0,1-436.477-287.187Zm0-22.4a10.22,10.22,0,0,0-10.208,10.209,10.221,10.221,0,0,0,10.208,10.208,10.22,10.22,0,0,0,10.209-10.208,10.22,10.22,0,0,0-10.209-10.208Z"
        transform="translate(448.665 311.564)"
        :fill="color.main"
      />
      <path
        id="路径_975"
        data-name="路径 975"
        d="M-436.38-296.5a2.784,2.784,0,0,1-2.782-2.781,2.786,2.786,0,0,1,2.782-2.782,2.785,2.785,0,0,1,2.782,2.782A2.783,2.783,0,0,1-436.38-296.5Z"
        transform="translate(448.568 311.467)"
        :fill="color.main"
      />
      <path
        id="Path_1014"
        data-name="Path 1014"
        d="M-442.581-307.5a8.5,8.5,0,0,1,10.654,1.24c.012-1.18-.521-2.826-1.508-3.322-1.1-.554-4.083-1.725-4.083-1.725Z"
        transform="translate(448.603 311.561)"
        :fill="color.main"
      />
      <path
        id="Path_1015"
        data-name="Path 1015"
        d="M-432.078-289.952a8.47,8.47,0,0,1-9.953-3.942c-.313,1.137-.1,3.111.728,3.843.924.819,3.386,2.465,3.386,2.465Z"
        transform="translate(448.598 311.383)"
        :fill="color.main"
      />
    </svg>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import colorConfig from '@/components/Explore/Icon/colorConfig';

export default defineComponent({
  name: 'AlbumsIcon',
  props: {

    mainType: {
      type: String,
      default: '',
    },
    colorType: {
      type: String,
      default: 'hot',
    },
    currIcon: {
      type: String,
      default: '',
    },
    iconKey: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const color = computed(() => {
      const route = useRoute();

      // 僅 explore 頁面使用
      if (props.iconKey === route.params.type) {
        return colorConfig.exploreActive;
      }

      if (props.iconKey === props.currIcon) {
        return colorConfig[props.colorType];
      }

      return colorConfig[props.mainType];
    });

    return {
      color,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@/styles/share/exploreIcon.scss';
</style>
