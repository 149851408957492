<template>
  <div class="explore-icon explore-icon--playlists">
    <svg xmlns="http://www.w3.org/2000/svg" width="21.106" height="23.448" viewBox="0 0 21.106 23.448">
      <g id="Group_2677" data-name="Group 2677" transform="translate(0)">
        <path
          id="路径_969"
          data-name="路径 969"
          d="M-288.527-297.16v-12.612a.986.986,0,0,1,.986-.986.986.986,0,0,1,.986.986v12.612a.986.986,0,0,1-.986.986A.986.986,0,0,1-288.527-297.16Z"
          transform="translate(301.014 310.758)"
          :fill="color.main"
        />
        <path
          id="路径_964"
          data-name="路径 964"
          d="M-290.644-301.23a4.03,4.03,0,0,1,4.03,4.029,4.028,4.028,0,0,1-4.028,4.03h0a4.03,4.03,0,0,1-4.029-4.03A3.981,3.981,0,0,1-290.644-301.23Z"
          transform="translate(301.077 310.66)"
          :fill="color.main"
        />
        <path
          id="路径_971"
          data-name="路径 971"
          d="M-297.409-296.759a.4.4,0,0,1-.472-.3,6.381,6.381,0,0,1,4.873-7.583.4.4,0,0,1,.472.3.4.4,0,0,1-.3.472,5.586,5.586,0,0,0-4.265,6.638A.4.4,0,0,1-297.409-296.759Z"
          transform="translate(301.111 310.695)"
          :fill="color.main"
        />
        <path
          id="路径_973"
          data-name="路径 973"
          d="M-300.373-296.152a.4.4,0,0,1-.472-.3,9.386,9.386,0,0,1,7.167-11.154.4.4,0,0,1,.472.3.4.4,0,0,1-.3.472,8.59,8.59,0,0,0-6.556,10.2.4.4,0,0,1-.3.476Z"
          transform="translate(301.142 310.726)"
          :fill="color.sub"
        />
        <path
          id="Path_1024"
          data-name="Path 1024"
          d="M-300.956-297.038a14.573,14.573,0,0,1,1.183-8.6,5.408,5.408,0,0,0,.738,1.8l-1.344,2.839Z"
          transform="translate(301.143 310.706)"
          :fill="color.sub"
        />
        <path
          id="Path_1025"
          data-name="Path 1025"
          d="M-297.817-300.031a8.342,8.342,0,0,1,3.2-4.9,4.484,4.484,0,0,0-.075,1.419Z"
          transform="translate(301.109 310.698)"
          :fill="color.main"
        />
        <g id="Group_2676" data-name="Group 2676" transform="translate(12.828 11.533)">
          <path
            id="路径_971-2"
            data-name="路径 971"
            d="M-283.819-298.246a.4.4,0,0,1,.492.27,6.381,6.381,0,0,1-4.347,7.9.4.4,0,0,1-.492-.27.4.4,0,0,1,.271-.492,5.587,5.587,0,0,0,3.806-6.912A.4.4,0,0,1-283.819-298.246Z"
            transform="translate(288.182 299.097)"
            :fill="color.main"
          />
          <path
            id="路径_973-2"
            data-name="路径 973"
            d="M-280.927-299.09a.4.4,0,0,1,.492.27,9.386,9.386,0,0,1-6.395,11.614.4.4,0,0,1-.492-.271.4.4,0,0,1,.271-.492,8.59,8.59,0,0,0,5.849-10.623.4.4,0,0,1,.264-.495Z"
            transform="translate(288.173 299.106)"
            :fill="color.sub"
          />
          <path
            id="Path_1026"
            data-name="Path 1026"
            d="M-280.227-298.216a14.565,14.565,0,0,1-.6,8.662,5.382,5.382,0,0,0-.858-1.741l1.149-2.923Z"
            transform="translate(288.115 299.097)"
            :fill="color.sub"
          />
          <path
            id="Path_1027"
            data-name="Path 1027"
            d="M-283.167-294.979a8.347,8.347,0,0,1-2.857,5.109,4.5,4.5,0,0,0-.022-1.421Z"
            transform="translate(288.16 299.064)"
            :fill="color.main"
          />
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import colorConfig from '@/components/Explore/Icon/colorConfig';

export default defineComponent({
  name: 'MoodsIcon',
  props: {
    mainType: {
      type: String,
      default: '',
    },
    colorType: {
      type: String,
      default: 'hot',
    },
    currIcon: {
      type: String,
      default: '',
    },
    iconKey: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const route = useRoute();
    const color = computed(() => {
      // 僅 explore 頁面使用
      if (props.iconKey === route.params.type) {
        return colorConfig.exploreActive;
      }

      if (props.iconKey === props.currIcon) {
        return colorConfig[props.colorType];
      }

      return colorConfig[props.mainType];
    });

    return {
      color,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@/styles/share/exploreIcon.scss';
</style>
