<template>
  <div
    class="mi-select"
    :class="[
      `mi-select--flex-direction--${flexDirection}`,
      {
        'mi-select--white': color === 'white',
        'mi-select--preview-tool': isPreviewTool
      },
    ]"
    :style="{ 'max-width': `${maxWidth}` }"
  >
    <p class="mi-select__label">{{ label }} <span v-if="label">:</span></p>
    <div class="mi-select-wrap">
      <div class="mi-select__box"
      :style="{

        width: w
      }"

      @click="toggleExpand(true)">
        <p class="mi-select__box__value">{{ value }}</p>
        <div class="expand-down" :class="{ 'expand-down--active': visible }">
          <img class="expand-down__icon" src="@/assets/searchResults/icon_info_close.svg" />
        </div>
      </div>

      <n-collapse-transition :show="visible">
        <div class="mi-select__option" v-click-away="() => toggleExpand(false)">
          <ul class="mi-select__option__list">
            <mi-select-option
              v-for="opt of formatOptions"
              :key="opt.value"
              v-bind="opt"
              :currOption="value"
              @handleOption="handleOption"
            />
          </ul>
        </div>
      </n-collapse-transition>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from 'vue';
import { NCollapseTransition } from 'naive-ui';
import { isNumber } from 'lodash-es';
import MiSelectOption from '@/components/Global/Select/SelectOption.vue';

export default defineComponent({
  name: 'MiSelect',
  components: {
    MiSelectOption,
    NCollapseTransition,
  },
  props: {
    label: {
      type: String,
      default: 'Category',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    width: {
      type: String,
      default: '150',
    },
    maxWidth: {
      type: String,
      default: 'auto',
    },
    color: {
      type: String,
      default: 'black',
    },
    isPreviewTool: {
      type: Boolean,
      default: false,
    },
    flexDirection: {
      type: String,
      default: 'row',
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const visible = ref(false);

    const toggleExpand = (status) => {
      visible.value = status;
    };

    const handleOption = (value) => {
      emit('update:value', value);
      toggleExpand(false);
    };

    const formatOptions = computed(() => props.options.reduce((optionsList, item) => {
      // 傳進來是群組時，把該 label 和 選項攤平，如何渲染是 SelectOption 的事
      if (item.options) {
        return [
          ...optionsList,
          {
            // type: 'group' 用來讓下面的 SelectOption 判斷是否為群組標籤
            type: 'label',
            label: item.label,
          },
          ...item.options,
        ];
      }

      return [...optionsList, item];
    }, []));

    const selectedValue = computed(() => {
      const valueMappingObj = formatOptions.value.reduce((obj, ele) => {
        obj[ele.value] = ele.label;
        return obj;
      }, {});

      return valueMappingObj[props.value];
    });

    const w = computed(() => {
      if (props.width === 'auto' || props.width === '100%') {
        return props.width;
      }

      if (isNumber(Number(props.width))) {
        return `${props.width}px`;
      }

      return 'auto';
    });

    return {
      toggleExpand,
      handleOption,
      selectedValue,
      formatOptions,
      visible,
      w,
    };
  },
});
</script>

<style lang="scss" scoped>
.mi-select {
  // width: 100%;
  position: relative;

  &--flex-direction {
    &--row {
      @include inline-flex(flex-start, center, row);
      .mi-select-wrap {
        margin-top: 0;
      }
    }

    &--column {
      @include inline-flex(flex-start, flex-start, column);
    }
  }

  &--white {
    .mi-select__label {
      color: $c-white;
    }

    .mi-select__box__value {
      color: $c-white;
    }
  }

  &--preview-tool {
    width: 100%;

    .mi-select__label {
      margin-right: 0px;
    }

    .mi-select__box {
      height: 50px;
      border-radius: 25px;
      width: 100%;
    }

    .mi-select-wrap {
      width: 100%;
    }
  }

  & + & {
    margin-left: 42px;
  }

  &__label {
    @include font-style($c-black, 14, normal, 0.7px, 20px);
    margin-right: 12px;
  }

  &__box {

    width: 120px;
    border: 1px solid $c-assist3;
    @include flex(center, flex-start, column);
    border-radius: 20px;
    height: 40px;
    @include padding(8px 14px);
    cursor: pointer;

    &__value {
      position: relative;
      cursor: pointer;
      @include font-style($c-black, 14, normal, 0.7px, 18px);
    }
  }

  &__option {
    width: 100%;
    @include position(tl, 100%, 0);
    z-index: 100;
    background-color: $c-white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 5px #00000029;
    width: 100%;

    &__list {
      width: 100%;
    }

    &__content {
      position: relative;
    }
  }
}

.mi-select-wrap {
  position: relative;
  margin-top: 8px;
}

.expand-down {
  @include position(tr, 50%, 10px);
  transform: translateY(-50%) rotate(180deg);
  transition: 0.4s;

  &__icon {
    width: 25px;
  }

  &--active {
    transform: translateY(-50%) rotate(360deg);
  }
}

@media screen and (min-width: $tablet) {
  .mi-select {
    @include inline-flex();
  }

  .mi-select-wrap {
    margin-top: 0;
  }
}
</style>
