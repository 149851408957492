export default {
  hot: {
    main: '#CECDCD',
    sub: '#CECDCD',
  },
  hotActive: {
    main: '#BA2424',
    sub: '#000000',
  },
  explore: {
    main: '#808080',
    sub: '#808080',
  },
  exploreActive: {
    main: '#ffffff',
    sub: '#ffffff',
  },
};
