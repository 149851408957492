<template>
  <div class="explore-icon explore-icon--playlists">
<svg id="Group_2672" data-name="Group 2672" xmlns="http://www.w3.org/2000/svg" width="27.12" height="24.108" viewBox="0 0 27.12 24.108">
  <path id="路径_964" data-name="路径 964" d="M-377.143-296.535a4.751,4.751,0,0,1,4.751,4.751,4.751,4.751,0,0,1-4.75,4.751h0a4.752,4.752,0,0,1-4.752-4.751A4.7,4.7,0,0,1-377.143-296.535Z" transform="translate(394.3 311.141)" :fill="color.main"/>
  <path id="路径_967" data-name="路径 967" d="M-372.319-309.256v17.363a.99.99,0,0,1-.99.99h0a.99.99,0,0,1-.99-.99V-310.3a.989.989,0,0,1,.842-.979,6.351,6.351,0,0,1,6.355,4.444A6.483,6.483,0,0,0-372.319-309.256Z" transform="translate(394.222 311.292)" :fill="color.main"/>
  <path id="路径_968" data-name="路径 968" d="M-381.289-307.571h-12.149a.99.99,0,0,1-.99-.99.99.99,0,0,1,.99-.99h12.149a.99.99,0,0,1,.99.99A.99.99,0,0,1-381.289-307.571Z" transform="translate(394.428 311.274)" :fill="color.sub"/>
  <path id="路径_969" data-name="路径 969" d="M-381.289-300.238h-12.149a.99.99,0,0,1-.99-.99.99.99,0,0,1,.99-.99h12.149a.989.989,0,0,1,.99.99A.99.99,0,0,1-381.289-300.238Z" transform="translate(394.428 311.199)" :fill="color.main"/>
  <path id="Path_1016" data-name="Path 1016" d="M-380.043-288a7.571,7.571,0,0,1-2.743-7.453A15.341,15.341,0,0,0-381-293.685C-380.393-293.274-380.043-288-380.043-288Z" transform="translate(394.31 311.13)" :fill="color.main"/>
  <path id="Path_1017" data-name="Path 1017" d="M-381.43-293.814c.16-2.242,3.113-4.4,4.933-4.114a5.625,5.625,0,0,0-1.213,2.756" transform="translate(394.295 311.156)" :fill="color.main"/>
  <path id="Path_1018" data-name="Path 1018" d="M-393.428-309.569c4.027,0,5.344-1.189,5.878-1.723a2.092,2.092,0,0,1-1.892,2.043" transform="translate(394.418 311.292)"  :fill="color.sub"/>
  <path id="路径_968-2" data-name="路径 968" d="M-387.181-292.408h-6.257a.99.99,0,0,1-.99-.99.99.99,0,0,1,.99-.99h6.257a.99.99,0,0,1,.99.99A.99.99,0,0,1-387.181-292.408Z" transform="translate(394.428 311.119)"  :fill="color.sub"/>
  <path id="Path_1019" data-name="Path 1019" d="M-389.853-302.235c5.873-.076,8.086-1.189,8.621-1.723a2.092,2.092,0,0,1-1.892,2.043" transform="translate(394.381 311.217)" :fill="color.main"/>
</svg>

  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import colorConfig from '@/components/Explore/Icon/colorConfig';

export default defineComponent({
  name: 'PlaylistsIcon',
  props: {
    mainType: {
      type: String,
      default: '',
    },
    colorType: {
      type: String,
      default: 'hot',
    },
    currIcon: {
      type: String,
      default: '',
    },
    iconKey: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const route = useRoute();
    const color = computed(() => {
      // 僅 explore 頁面使用
      if (props.iconKey === route.params.type) {
        return colorConfig.exploreActive;
      }

      if (props.iconKey === props.currIcon) {
        return colorConfig[props.colorType];
      }

      return colorConfig[props.mainType];
    });

    return {
      color,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@/styles/share/exploreIcon.scss';
</style>
