<template>
  <div class="explore-icon explore-icon--playlists">
    <svg
      id="Group_2680"
      data-name="Group 2680"
      xmlns="http://www.w3.org/2000/svg"
      width="25.502"
      height="23.852"
      viewBox="0 0 25.502 23.852"
    >
      <path
        id="路径_969"
        data-name="路径 969"
        d="M-337.985-290.805v-15.558a.99.99,0,0,1,.99-.99.99.99,0,0,1,.99.99v15.558a.989.989,0,0,1-.99.99A.989.989,0,0,1-337.985-290.805Z"
        transform="translate(344.603 310.996)"
        :fill="color.main"
      />
      <path
        id="路径_969-2"
        data-name="路径 969"
        d="M-323.328-303.612a.718.718,0,0,1-.719-.719v-5.107a.718.718,0,0,1,.719-.719.719.719,0,0,1,.719.719v5.107A.719.719,0,0,1-323.328-303.612Z"
        transform="translate(344.46 311.025)"
        :fill="color.sub"
      />
      <path
        id="路径_969-3"
        data-name="路径 969"
        d="M-326.744-302.726a.719.719,0,0,1-.719-.719v-5.107a.719.719,0,0,1,.719-.719.719.719,0,0,1,.719.719v5.107A.719.719,0,0,1-326.744-302.726Z"
        transform="translate(344.495 311.016)"
        :fill="color.sub"
      />
      <path
        id="路径_969-4"
        data-name="路径 969"
        d="M-330.16-303.612a.719.719,0,0,1-.719-.719v-5.107a.719.719,0,0,1,.719-.719.719.719,0,0,1,.719.719v5.107A.719.719,0,0,1-330.16-303.612Z"
        transform="translate(344.53 311.025)"
        :fill="color.sub"
      />
      <path
        id="路径_969-5"
        data-name="路径 969"
        d="M-333.576-302.726a.718.718,0,0,1-.719-.719v-5.107a.718.718,0,0,1,.719-.719.719.719,0,0,1,.719.719v5.107A.719.719,0,0,1-333.576-302.726Z"
        transform="translate(344.565 311.016)"
        :fill="color.sub"
      />
      <path
        id="路径_969-6"
        data-name="路径 969"
        d="M-320.905-292.748v-17.3a.99.99,0,0,1,.99-.99.99.99,0,0,1,.99.99v17.3a.989.989,0,0,1-.99.99A.989.989,0,0,1-320.905-292.748Z"
        transform="translate(344.428 311.034)"
        :fill="color.main"
      />
      <g id="Group_2678" data-name="Group 2678" transform="translate(0 14.926)">
        <path
          id="路径_964"
          data-name="路径 964"
          d="M-339.954-294.793a3.889,3.889,0,0,1,3.889,3.889,3.888,3.888,0,0,1-3.888,3.889h0a3.889,3.889,0,0,1-3.889-3.889A3.843,3.843,0,0,1-339.954-294.793Z"
          transform="translate(344.663 295.942)"
          :fill="color.main"
        />
        <path
          id="Path_1028"
          data-name="Path 1028"
          d="M-342.327-287.81a6.2,6.2,0,0,1-2.245-6.1,12.472,12.472,0,0,0,1.464,1.45C-342.614-292.124-342.327-287.81-342.327-287.81Z"
          transform="translate(344.671 295.933)"
          :fill="color.main"
        />
        <path
          id="Path_1029"
          data-name="Path 1029"
          d="M-343.463-292.565c.132-1.835,2.548-3.6,4.038-3.367a4.594,4.594,0,0,0-.994,2.256"
          transform="translate(344.659 295.954)"
          :fill="color.main"
        />
      </g>
      <g id="Group_2679" data-name="Group 2679" transform="translate(16.906 13.187)">
        <path
          id="路径_964-2"
          data-name="路径 964"
          d="M-322.874-296.549a3.888,3.888,0,0,1,3.889,3.888,3.887,3.887,0,0,1-3.887,3.889h0a3.889,3.889,0,0,1-3.889-3.889A3.843,3.843,0,0,1-322.874-296.549Z"
          transform="translate(327.582 297.698)"
          :fill="color.main"
        />
        <path
          id="Path_1030"
          data-name="Path 1030"
          d="M-325.247-289.566a6.2,6.2,0,0,1-2.244-6.1,12.469,12.469,0,0,0,1.464,1.45C-325.533-293.88-325.247-289.566-325.247-289.566Z"
          transform="translate(327.591 297.689)"
          :fill="color.main"
        />
        <path
          id="Path_1031"
          data-name="Path 1031"
          d="M-326.383-294.322a4.2,4.2,0,0,1,4.038-3.367,4.609,4.609,0,0,0-.993,2.256"
          transform="translate(327.578 297.71)"
          :fill="color.main"
        />
      </g>
    </svg>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import colorConfig from '@/components/Explore/Icon/colorConfig';

export default defineComponent({
  name: 'GenresIcon',
  props: {
    mainType: {
      type: String,
      default: '',
    },
    colorType: {
      type: String,
      default: 'hot',
    },
    currIcon: {
      type: String,
      default: '',
    },
    iconKey: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const route = useRoute();

    const color = computed(() => {
      // 僅 explore 頁面使用
      if (props.iconKey === route.params.type) {
        return colorConfig.exploreActive;
      }

      if (props.iconKey === props.currIcon) {
        return colorConfig[props.colorType];
      }

      return colorConfig[props.mainType];
    });

    return {
      color,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@/styles/share/exploreIcon.scss';
</style>
