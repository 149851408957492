<template>
  <div class="explore-icon explore-icon--themes">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <g id="Group_4535" data-name="Group 4535" transform="translate(13123 -10738)">
        <rect
          id="Rectangle_1158"
          data-name="Rectangle 1158"
          width="30"
          height="30"
          transform="translate(-13123 10738)"
          :fill="color.main"
          opacity="0"
        />
        <g id="Group_4534" data-name="Group 4534" transform="translate(-5266.456 53.999)">
          <path
            id="Union_3"
            data-name="Union 3"
            d="M5229.164-32.356a3.869,3.869,0,0,1-2.385-.817,6.207,6.207,0,0,1-2.223-6.088s.618.71,1.118,1.167c.283-1.761,2.58-3.394,4.016-3.168a5.5,5.5,0,0,0-.684,1.126h.158a3.869,3.869,0,0,1,1.907.5V-53.01a.991.991,0,0,1,.99-.99.99.99,0,0,1,.989.99v16.663c0,.034,0,.068,0,.1s0,.069,0,.1v.427a.988.988,0,0,1-.138.5,3.89,3.89,0,0,1-3.748,2.854Zm10.217-1.7c-.362,0-.659-.41-.659-.913a.845.845,0,0,1,.608-.91l-.055,0c5.415-.07,7.455-1.1,7.946-1.588a2,2,0,0,1-.876,1.588h1.121c.364,0,.659.408.659.913s-.295.913-.659.913Zm-3.367-5.691a.913.913,0,0,1-.913-.913.913.913,0,0,1,.913-.913h11.2a.914.914,0,0,1,.913.913.914.914,0,0,1-.913.913Zm3.367-5.693c-.362,0-.659-.408-.659-.911s.3-.913.659-.913h1.122a2,2,0,0,1-.877-1.588c.492.493,2.533,1.517,7.947,1.588l-.056,0a.844.844,0,0,1,.608.91c0,.5-.295.911-.659.911Zm-3.367-5.691a.912.912,0,0,1-.913-.911.912.912,0,0,1,.913-.913h11.2a.913.913,0,0,1,.913.913.913.913,0,0,1-.913.911Z"
            transform="translate(-13078 10742.001)"
            :fill="color.main"
          />
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import colorConfig from '@/components/Explore/Icon/colorConfig';

export default defineComponent({
  name: 'GenresIcon',
  props: {

    mainType: {
      type: String,
      default: '',
    },
    colorType: {
      type: String,
      default: '',
    },
    currIcon: {
      type: String,
      default: '',
    },
    iconKey: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const color = computed(() => {
      const route = useRoute();

      // 僅 explore 頁面使用
      if (props.iconKey === route.params.type) {
        return colorConfig.exploreActive;
      }

      if (props.iconKey === props.currIcon) {
        return colorConfig[props.colorType];
      }

      return colorConfig[props.mainType];
    });

    return {
      color,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@/styles/share/exploreIcon.scss';

</style>
