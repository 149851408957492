<template>
  <div class="c c-album-and-playlists">
    <div class="popular popular--bg-black">
      <div
        :class="{
          'g-content': addGContent,
        }"
      >
        <popular-box-rwd-transform :data="popularPlaylistsMockData">
          <template v-slot:header>
            <explore-title title="精選歌單" color="white" />
          </template>
          <template v-slot="playlistsData">
            <playlists-box-popular color="white" :key="i" :data="playlistsData.data" />
          </template>
        </popular-box-rwd-transform>
      </div>
    </div>

    <div class="normal">
      <div class="normal__content g-content">

        <normal-box-rwd-transform :data="normalPlaylistsMockData">
          <template v-slot:header>
            <explore-title title="所有歌單" flexDirection="column">
              <template v-slot:extra>
                <SeeMore text="See all in search" :to="to" :show="['init', 'tablet']"/>
              </template>
              <template v-slot:right>
                <div class="select-wrap">
                  <mi-select v-model:value="sortValue" :options="mockOptions" label="Sort by" flexDirection="column" />
                  <mi-select
                    v-model:value="categoryValue"
                    :options="mockOptions"
                    label="Category"
                    flexDirection="column"
                  />
                </div>
              </template>
            </explore-title>
          </template>
          <template v-slot="playlistsData">
            <playlists-box-normal color="white" :data="playlistsData.data" />
          </template>
        </normal-box-rwd-transform>

        <mi-search-pagination :pager="pager" @handlePagination="handlePagination" isWhiteBg />
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent, ref, reactive,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import PlaylistsBoxPopular from '@/components/Explore/PlaylistsBoxPopular.vue';
import PlaylistsBoxNormal from '@/components/Explore/PlaylistsBoxNormal.vue';
import ExploreTitle from '@/components/Explore/ExploreTitle.vue';
import SeeMore from '@/components/Global/SeeMore.vue';
import MiSelect from '@/components/Global/Select/index.vue';
// import MiPagination from '@/components/Global/MiPagination.vue';
import { popularPlaylistsMockData, normalPlaylistsMockData } from '@/config/mockData/playlistsBox';
import PopularBoxRwdTransform from '@/components/Global/PopularBoxRwdTransform.vue';
import NormalBoxRwdTransform from '@/components/Global/NormalBoxRwdTransform.vue';
import useAddGContent from '@/hooks/useAddGContent';
import MiSearchPagination from '@/components/Global/MiSearchPagination.vue';

export default defineComponent({
  name: 'Playlists',
  components: {
    PlaylistsBoxPopular,
    PlaylistsBoxNormal,
    ExploreTitle,
    SeeMore,
    MiSelect,
    // MiPagination,
    PopularBoxRwdTransform,
    NormalBoxRwdTransform,
    MiSearchPagination,
  },
  setup() {
    const addGContent = useAddGContent();

    const router = useRouter();
    const route = useRoute();
    const sortValue = ref('name');
    const categoryValue = ref('newest');

    const pager = reactive({
      pageIndex: route.query?.pageIndex || 1,
      totalPage: 2,
    });

    const mockOptions = [
      {
        label: 'Name',
        value: 'name',
      },
      {
        label: 'Newest',
        value: 'newest',
      },
      {
        label: 'Oldest',
        value: 'oldest',
      },
    ];

    const to = {
      name: 'search',
      query: {
        tab: 'playlists',
      },
    };

    const handlePagination = (targetPage) => {
      pager.pageIndex = targetPage;

      router.push({
        name: 'explore-type',
        params: {
          type: 'playlists',
        },
        query: {
          pageIndex: pager.pageIndex,
        },
      });
    };

    return {
      mockOptions,
      sortValue,
      categoryValue,
      to,
      pager,
      handlePagination,
      popularPlaylistsMockData,
      normalPlaylistsMockData,
      addGContent,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/albumsAndPlaylists.scss';
</style>
